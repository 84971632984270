import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import Spinner from "./Spinner.js";
import "../styles/PDFGeneratorModal.css";
import Config from "../config.js";

const PDFGeneratorModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    file: null,
    certificateType: "FLANSCHMONTEUR",
    printableVersion: true,
    schulungsleiter: "Snezana Heckmann",
    begutachter: "Marko Dinic",
  });

  const [popupMessage, setPopupMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner
    const token = localStorage.getItem("token");
    if (!token) {
      setPopupMessage("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.");
      setPopupVisible(true);
      setLoading(false);
      return;
    }

    if (!formData.file) {
      setPopupMessage("Bitte wählen Sie eine Datei zum Hochladen aus.");
      setPopupVisible(true);
      setLoading(false);
      return;
    }

    const data = new FormData();
    data.append("file", formData.file);
    data.append("certificateType", formData.certificateType);
    data.append("printableVersion", formData.printableVersion);
    data.append("schulungsleiter", formData.schulungsleiter);
    data.append("begutachter", formData.begutachter);

    try {
      const response = await axios.post(`${Config.API_BASE_URL}/generate`, data, {
        headers: {
          "x-access-token": token,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "zertifikate.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setPopupMessage("Zertifikate wurden erfolgreich generiert!");
      setPopupVisible(true);
    } catch (error) {
      console.error("Fehler beim Generieren der Zertifikate:", error);
      setPopupMessage(
        error.response?.status === 401
          ? "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."
          : "Fehler beim Generieren der Zertifikate. Bitte versuchen Sie es später erneut."
      );
      setPopupVisible(true);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    if (popupMessage.includes("erneut an")) {
      navigate("/"); // Redirect to login page
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Zertifikatsgenerator</h2>
        {loading ? (
          <Spinner /> // Display spinner during loading
        ) : (
          <form onSubmit={handleGenerate}>
            {/* File Upload */}
            <div className="form-group">
              <label htmlFor="fileInput">Datei für Zertifikatsdaten hochladen</label>
              <input
                type="file"
                name="file"
                id="fileInput"
                accept=".xls,.xlsx,.csv"
                required
                onChange={handleFileChange}
              />
            </div>

            {/* Certificate Type Dropdown */}
            <div className="form-group">
              <label htmlFor="certificateType">Zertifikatstyp</label>
              <select
                id="certificateType"
                name="certificateType"
                value={formData.certificateType}
                onChange={(e) =>
                  setFormData({ ...formData, certificateType: e.target.value })
                }
              >
                <option value="FLANSCHMONTEUR">FLANSCHMONTEUR</option>
                <option value="CERTIFICATE_TYPE_2">Zertifikatstyp 2</option>
              </select>
            </div>

            {/* Schulungsleiter Dropdown */}
            <div className="form-group">
              <label htmlFor="schulungsleiter">Schulungsleiter</label>
              <select
                id="schulungsleiter"
                name="schulungsleiter"
                value={formData.schulungsleiter}
                onChange={(e) =>
                  setFormData({ ...formData, schulungsleiter: e.target.value })
                }
              >
                <option value="Snezana Heckmann">Snezana Heckmann</option>
                <option value="Trainer 2">Trainer 2</option>
              </select>
            </div>

            {/* Printable Version Toggle */}
            <div className="form-group">
              <label htmlFor="printableVersion" className="toggle-label">
                Druckbare Version generieren
                <div className="toggle-container">
                  <input
                    type="checkbox"
                    id="printableVersion"
                    checked={formData.printableVersion}
                    onChange={(e) =>
                      setFormData({ ...formData, printableVersion: e.target.checked })
                    }
                  />
                  <span className="slider"></span>
                </div>
              </label>
            </div>

            {/* Buttons */}
            <div className="form-buttons">
              <button type="submit" className="btn-primary">
                Zertifikate generieren
              </button>
              <button type="button" className="btn-secondary" onClick={closeModal}>
                Schließen
              </button>
            </div>
          </form>
        )}
        {popupVisible && <Popup message={popupMessage} onClose={handlePopupClose} />}
      </div>
    </div>
  );
};

export default PDFGeneratorModal;
