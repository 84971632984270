import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";
import Config from "../config.js";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already authenticated
    const validateToken = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await fetch(`${Config.API_BASE_URL}/api/validate-token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            navigate("/dashboard");
          } else {
            localStorage.removeItem("token"); // Remove invalid/expired token
          }
        } catch (err) {
          console.error("Fehler beim Überprüfen des Tokens:", err);
          localStorage.removeItem("token"); // Remove token if validation fails
        }
      }
    };

    validateToken();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const apiUrl = `${Config.API_BASE_URL}/api/login`;

    setLoading(true);
    setError("");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token); // Save token for future use
        setLoading(false);
        setError("");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        setLoading(false);
        setError(errorData.message || "Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.");
      }
    } catch (err) {
      setLoading(false);
      setError("Serververbindung fehlgeschlagen. Bitte versuchen Sie es später erneut.");
    }
  };

  return (
    <div className="login-page">
      <header className="login-header">
        <img src="/logo.png" alt="Auraplan Logo" className="login-logo" />
        <h1>Auraplan Digital Portal</h1>
      </header>
      <div className="login-container">
        <h2>Anmeldung</h2>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <label htmlFor="username">Benutzername</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Geben Sie Ihren Benutzernamen ein"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Passwort</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Geben Sie Ihr Passwort ein"
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? "Anmeldung läuft..." : "Anmelden"}
          </button>
        </form>
      </div>
      <footer className="login-footer">
        <p>© Auraplan Academy. Alle Rechte vorbehalten.</p>
      </footer>
    </div>
  );
};

export default Login;
