import { useState } from "react";
import "../styles/PDFGeneratorModal.css";

const PDFGeneratorModal = ({ isOpen, closeModal }) => {
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    zertifizierungsstandard: "ISO/IEC 17024",
    berufsqualifikationen: "FLANSCHMONTEUR",
    technische_normen: "EN-1591-4",
    schulungsleiter: "Snezana Heckmann",
    begutachter: "Marko Dinic",
  });
  const [status, setStatus] = useState("");

  if (!isOpen) return null; // Don't render if modal is not open

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const uploadFile = async () => {
    if (!file) {
      setStatus("Please select a file to upload.");
      return;
    }
  
    const uploadData = new FormData();
    uploadData.append("file", file);
    Object.entries(formData).forEach(([key, value]) =>
      uploadData.append(key, value)
    );
  
    try {
      const response = await fetch("/generate", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: uploadData,
      });
      if (response.ok) {
        setStatus("Certificates generated successfully!");
      } else {
        setStatus("Error generating certificates.");
      }
    } catch (error) {
      setStatus("An error occurred while uploading.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <header className="modal-header">
          <h2>Generate Certificates</h2>
          <button className="close-btn" onClick={closeModal}>
            &times;
          </button>
        </header>
        <main className="modal-body">
          <label>Upload File</label>
          <input type="file" onChange={handleFileChange} />

          <label>Zertifizierungsstandard</label>
          <select name="zertifizierungsstandard" onChange={handleInputChange}>
            <option value="ISO/IEC 17024">ISO/IEC 17024</option>
            <option value="Standard 1">Standard 1</option>
          </select>

          <label>Berufsqualifikationen</label>
          <select name="berufsqualifikationen" onChange={handleInputChange}>
            <option value="FLANSCHMONTEUR">FLANSCHMONTEUR</option>
          </select>

          <label>Technische Normen</label>
          <select name="technische_normen" onChange={handleInputChange}>
            <option value="EN-1591-4">EN-1591-4</option>
          </select>

          <label>Schulungsleiter</label>
          <select name="schulungsleiter" onChange={handleInputChange}>
            <option value="Snezana Heckmann">Snezana Heckmann</option>
          </select>

          <label>Begutachter</label>
          <select name="begutachter" onChange={handleInputChange}>
            <option value="Marko Dinic">Marko Dinic</option>
          </select>

          <button className="submit-btn" onClick={uploadFile}>
            Generate PDFs
          </button>
          {status && <p className="status-message">{status}</p>}
        </main>
      </div>
    </div>
  );
};

export default PDFGeneratorModal;
