import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PDFGeneratorModal from "../components/PDFGeneratorModal";
import "../styles/Dashboard.css";

const Dashboard = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="dashboard-container">
      {/* Header Section */}
      <header className="dashboard-header">
        <div className="logo-section">
          <img src="/logo.png" alt="Auraplan Logo" className="logo-image" />
          <span className="dashboard-title">Auraplan Digital Portal</span>
        </div>
        <button
          className="logout-btn"
          onClick={() => {
            localStorage.removeItem("token");
            navigate("/");
          }}
        >
          Abmelden
        </button>
      </header>

      {/* Main Content */}
      <main className="dashboard-main">
        <h1>Willkommen im Dashboard</h1>
        <p>Verwalten Sie Ihre Zertifikate und mehr:</p>
        <button className="action-btn" onClick={openModal}>
          PDF-Generator öffnen
        </button>
      </main>

      {/* PDF Generator Modal */}
      {isModalOpen && <PDFGeneratorModal closeModal={closeModal} />}
    </div>
  );
};

export default Dashboard;
